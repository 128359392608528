.wf_moneyIndicator_wrapper {
    position: relative;
    z-index: 2;   
    display: flex;
}

.wf_moneyIndicator_baseSum {
    border:calc(var(--base) * 3) solid #c0730e;
    border-left: none;
    border-top: none;
    border-bottom-right-radius:calc(var(--base) * 5);
    padding:calc(var(--base) * 2)calc(var(--base) * 5);
}

.wf_transaction {
    position: absolute;
    top: 0;
    left: 0;
}

.wf_transaction_negative {
    color: #f00;
    text-shadow: #fff calc(var(--base) * 0)calc(var(--base) * 0)calc(var(--base) * 3);
    animation: transactionNeg 2s forwards;
}

.wf_transaction_positive {
    color: #0f0;
    text-shadow: #000000 calc(var(--base) * 0)calc(var(--base) * 0)calc(var(--base) * 3);
    animation: transactionPos 2s forwards;
}

@keyframes transactionPos{
    0%{
        opacity: 1;
        transform: translate(-2calc(var(--base) * 0), calc(var(--base) * 50));
    }

    100%{
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes transactionNeg{
    0%{
        opacity: 1;
        transform: translate(0, 0);
    }

    100%{
        opacity: 1;
        transform: translate(calc(var(--base) * 20), calc(var(--base) * 50));
    }
}