.wf_wrapper{
    --base: 1px;
    width:calc(var(--base) * 800);
    height:calc(var(--base) * 565);
    position: relative;
    overflow: hidden;
    margin: auto;
}

.wf_field{
    position: relative;
    width: 100%;
    height:calc(var(--base) * 300);
}

.wf_animal{
    position: absolute;
    width:calc(var(--base) * 10);
    height:calc(var(--base) * 10);
    background-color: #f00;
    transition: 1s linear
}

.wf_item{
    position: absolute;
    width:calc(var(--base) * 10);
    height:calc(var(--base) * 10);
    background-color: #00f;
}

.wf_basePopup{
    position: absolute;
    padding:calc(var(--base) * 10);
    background-color: #fff;
    top:calc(var(--base) * 0);
    left:calc(var(--base) * 0);
    display: flex;
    width:calc(var(--base) * 500);
    height:calc(var(--base) * 300);
}

.wf_basePopup_shade{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0009;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wf_basePopup_close{
    position: absolute;
    top: 0;
    right: 0;
}
