input[type="range"] {
    -webkit-appearance: none;
    appearance: none; 
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    height: 6px;
    background: #ccc;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none; 
    height: 15px;
    width: 15px;
    background-color: #f50;
    border-radius: 50%;
    border: none;
    transition: .2s ease-in-out;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #f50;
    border-radius: 50%;
    border: none;
    transition: .2s ease-in-out;
}

/* Hover, active & focus Thumb: Webkit */

input[type="range"]::-webkit-slider-thumb:hover {
    box-shadow: 0 0 0 4px rgba(255,85,0, .1)
}

input[type="range"]:active::-webkit-slider-thumb {
    box-shadow: 0 0 0 5px rgba(255,85,0, .2)
}

input[type="range"]:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 5px rgba(255,85,0, .2)
}

/* Hover, active & focus Thumb: Firfox */

input[type="range"]::-moz-range-thumb:hover {
    box-shadow: 0 0 0 10px rgba(255,85,0, .1)
}

input[type="range"]:active::-moz-range-thumb {
    box-shadow: 0 0 0 13px rgba(255,85,0, .2)
}

input[type="range"]:focus::-moz-range-thumb {
    box-shadow: 0 0 0 13px rgba(255,85,0, .2)    
}
