.row {
    display: flex;
  }
  
.cell {
    width: 30px;
    height: 30px;
    border: 1px solid #0002;
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    position: relative;
    color: #fff;
    border-radius: 3px;
    margin: 1px;
    background-color: #e8f5fe;
}

.cell_player{
    background-color: rgb(252, 185, 91);
}

.cell_value{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 10px;
}

.cell_letter {
   /* background-color: rgb(255 100 193);
    border: 2px solid #923b95;
    border-top: 2px solid #e7acdd;
    border-left: 2px solid #e8a6e9;
    transform: scale(1.08);*/
    background-color: rgb(104 150 226);
    border: 2px solid #2a4795;
    border-top: 2px solid #a6caff;
    border-left: 2px solid #b5ceff;
    transform: scale(1.08);
}

.cell_input {
    background-color: rgb(252, 185, 91);
}

.cell_2w{
    background-color:rgb(168 231 168);
}

.cell_2l{
    background-color:rgb(185 227 255);
}

.cell_3w{
    background-color: rgb(255, 153, 153);
}
.cell_3l{
    background-color:rgb(255 229 133);
}
.cell_start{
    background-color:rgb(245, 60, 91);
}

.field_scroll {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 700px;
    min-height: 500px;
    overflow: hidden;
    margin: 0px;
    border: 3px solid #ccc;
    background: #fff;
    color: #fff
}

.field_content {
    position: absolute;
}

.bottom_panel{
    display: flex;
    justify-content: center;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
}

.control_panel{
    background-color: #ffe3a1;
    padding: 10px 20px;
    border-radius: 5px;
    border: 2px solid #ffb928;
}

.controlButtons_list{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 5px;
}

.player_letters{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 5px;
}

.cell_player_expand{
    padding-left: 30px;
}

.cell_ghost{
    background-color: #ffb928;
    position: absolute;
    z-index: 10;
    pointer-events: none;
}

.showOptions_button{
    position: absolute;
    user-select: none;
    top: 2px;
    right: 2px;
}

.default_button{
    border: 2px solid #d6e7f9;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #8fbdde;
    color: #fff;
    border-bottom-color: #6e96ba;
    border-right-color: #6e96ba;
    cursor: pointer;
    margin: 0px 2px;
}

.default_button_inactive{
    border: 2px solid #ebebeb;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #cbd2d7;
    color: #f3f3f3;
    border-bottom-color: #b7bec4;
    border-right-color: #b7bec4;
    cursor: pointer;
    margin: 0px 2px;
}

