.wf_collectable{
   position: absolute;
    width:calc(var(--base) * 28);
    height:calc(var(--base) * 28);
    background-color: transparent; 
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
}

.wf_collectable_timeout{
    animation: timeout 250ms infinite alternate;
}

@keyframes timeout{
    0%{
        opacity: 1;
    }
    40%{
        opacity: 1;
    }
    60%{
        opacity: 0.5;
    }
    100%{
        opacity: 0.5;
    }
}

.wf_collectable_remove{
    animation: remove 500ms;
}


@keyframes remove{
    0%{
        transform: scale(1);
    }
    20%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(0);
    }
}
