.wf_mainMenu_wrapper{
    width:calc(var(--base) * 800);
    height:calc(var(--base) * 600);
    user-select: none;
    position: relative;
}

.wf_mainMenu_editTools{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.wf_editorTool{

}

.wf_editorTool_active{
    background-color: #9f9;
}

.wf_mainMenu_back{
    position: relative;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../assets/wf_menu.png");
}

.wf_mainMenu_levelButton{
    position: absolute;
    width:calc(var(--base) * 35);
    height:calc(var(--base) * 35);
    background-color: rgba(211, 205, 28, 0.866);
    border-radius: 100%;
    border:calc(var(--base) * 2) solid rgb(144, 120, 14);
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: #000 calc(var(--base) * 1)calc(var(--base) * 1)calc(var(--base) * 1);
    color: #fff;
}

.wf_mainMenu_levelButton_completed{
    background-color: rgba(9, 213, 40, 0.866);
    border:calc(var(--base) * 2) solid rgb(35, 119, 17);
}

.wf_mainMenu_levelButton_locked{
    background-color: rgba(145, 145, 145, 0.866);
    border:calc(var(--base) * 2) solid rgb(59, 59, 59);
}

.wf_mainMenu_debugPassLevel{
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    background: #909;
    width:calc(var(--base) * 12);
    height:calc(var(--base) * 12);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:calc(var(--base) * 8);
    border:calc(var(--base) * 2) solid #515;
    color: #ccc;
}