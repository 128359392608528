.card {
    /*width: 100px;
    height: 150px;*/
    width: 100%;
    height: 100%;
    position: relative;
    perspective: 1000px;
    --c-phase: 0;
    margin: 0px;  
}

.card_base{
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    border: 2px solid #fff;
    border-radius: 5px;
    transition: 300ms;
}

.card_a{
    background-color: #f00;
    transform: rotateY(calc(180deg * var(--c-phase) / 100));
    box-shadow: inset -2px -2px 0px #fff, inset 2px 2px 0px #fff;
}

.card_b{
    background-color: #ff0;
    transform: rotateY(calc(180deg + (180deg * var(--c-phase) / 100)));
}

.card_img{
    width: 100%;
    height: 100%;
    --type: 0;
    --value: 0;
    background-image: url("../../assets/card.png");
    background-size: calc(100% * 13) calc(100% * 5);
    background-position: calc(100% / 12  * var(--value)) calc(100% / 4 * var(--type));
}