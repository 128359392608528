.ani_wrapper{
    position: relative;
}

.ani_sprite{
    background-image: url('./img//sprite.png');
    background-repeat: no-repeat;
    position: absolute;
}

.ani_sprite2{
    background-image: url('./img//sprite2.png');
    background-repeat: no-repeat;
    position: absolute;
}

.ani_head_group{
    width: 100px;
    height: 50px;
    transform-origin: center bottom;
    animation: rh_kf linear infinite 5.5s;
    position: absolute;
}

.ani_fc{
    background-position: 0px -25px;
    background-size: 100px 100px;
    width: 100px;
    height: 100px;
    top: 8px;
}

.ani_lh{
    background-position: 0px 0px;
    background-size: 100px 100px;
    width: 50px;
    height: 25px;
    transform-origin: center bottom;
    animation: rh_kf linear infinite 3.5s;
}

.ani_rh{
    background-position: -50px 0px;
    background-size: 100px 100px;
    width: 50px;
    height: 25px;
    left:50px;
    transform-origin: center bottom;
    animation: rh_kf linear infinite 3s;
}

.ani_body{
    background-position: 0px -26px;
    background-size: 120px 100px;
    width: 128px;
    height: 100px;
    left: -18px;
    transform-origin: center bottom;
    top: 48px;
    animation: body_kf linear infinite 2s;
}

.ani_mus{
    background-position: 0px 0px;
    background-size: 100px 100px;
    width: 100px;
    height: 25px;
    left: 2px;
    top: 35px;
    transform-origin: center bottom;
    animation: rh_kf linear infinite 7s;
}

@keyframes body_kf{
    0%{
        transform: scale(1, 1.00);
    }

    50%{
        transform: scale(1, 1.03);
    }

    100%{
        transform: scale(1, 1.00);
    }
}

@keyframes rh_kf{
    0%{
        transform: rotate(0deg);
    }

    30%{
        transform: rotate(-2deg);
    }

    70%{
        transform: rotate(3deg);
    }

    100%{
        transform: rotate(0deg);
    }
}