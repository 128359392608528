.aniSlider{
    position: relative;
    display: flex;
    justify-content: center;
}

.aniSlider_wrapper{
    display: flex;
    --cols: 1;
    --offset: 0;
    background: #224;
    padding: 10px;
    width: 100%;
}

.aniSlider_arrowZero{
    display: flex;
    align-items: center;
    width: 0;
    position: relative;
    z-index: 1;
}

.aniSlider_arrowZero_right{
    justify-content: flex-end;
}

.aniSlider_arrowButton{
    background-image: url('./assets/arrow.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 0;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    opacity: 0.5;
}

.aniSlider_arrowButton_left{
    transform: scaleX(-1);
}

.aniSlider_arrowButton:hover{
    opacity: 0.8;
}

.aniSlider_slides{
    width: 100%;
    overflow: hidden;

    border-radius: 10px;
}

.aniSlider_slide{
    flex-shrink: 0;
    width: calc(100% / var(--cols));
    transform: translateX(calc(-100% + var(--offset)));
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0.660, -0.160, 0.200, 1.235);
    padding: 5px;
}

.aniSlider_slides_rescaler{
    display: flex;
    width: calc(100% + 2 * 5px);
    position: relative;
    left: -5px;
}

.aniSlider_slide_no_transition{
    transition-duration: 0ms;
}

.aniSlider_slide_move_left{
    animation: move_left 400ms forwards;
    /*animation-timing-function: cubic-bezier(0.660, -0.160, 0.200, 1.235);*/
}

.aniSlider_slide_move_right{
    animation: move_right 400ms forwards;
   /* animation-timing-function: cubic-bezier(0.660, -0.160, 0.200, 1.235);*/
}

.aniSlider_slide_move_left_force{
    animation: move_left 100ms forwards linear;
    /*animation-timing-function: cubic-bezier(0.660, -0.160, 0.200, 1.235);*/
}

.aniSlider_slide_move_right_force{
    animation: move_right 100ms forwards linear;
   /* animation-timing-function: cubic-bezier(0.660, -0.160, 0.200, 1.235);*/
}

.aniSlider_slide_content{
    width: 100%;
    height: 300px;
    background-color: #f99;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #fff9;
    user-select: none;
}

.aniSlider_slide_content1{
    background-color: #fc9;
}

.aniSlider_slide_content2{
    background-color: #9c9;
}

.aniSlider_slide_content3{
    background-color: #99f;
}

.aniSlider_pagination{
    position: absolute;
    bottom: 5px;
    display: flex;
    gap: 10px;
}

.aniSlider_pagination_button{
    border: none;
    padding: 0;
    margin: 0;
    width: 50px;
    height: 5px;
    background-color: #ccc;
}
.aniSlider_pagination_button_active{
    border: none;
    padding: 0;
    margin: 0;
    width: 50px;
    height: 5px;
    background-color: #090;
}


@keyframes move_left{
    0%{
        transform: translateX(-200%);
    }
    100%{
        transform: translateX(-100%);
    }
}

@keyframes move_right{
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(-100%);
    }
}

