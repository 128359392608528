.row {
  display: flex;
}

.cell {
  width: 30px;
  height: 30px;
  border: 1px solid;
  user-select: none;
  cursor: pointer;
}

.game_wrapper{
  position: relative;
  height: 100%;
  overflow: hidden;
  --size: 30px;
  --field-x: 10;
  --field-y: 10;
  width: 100%;
}

.field2 {
  position: absolute;
  /*overflow: hidden;*/
  width: calc(var(--size) * var(--field-x));
  height: calc(var(--size) * var(--field-y));
  top: 0;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cell2 {
  --posy:0;
  --posx:0;
  transition-timing-function: cubic-bezier(0.66, 0.25, 0.63, 1.26);
  position: absolute;
  width: var(--size);
  height: var(--size);
  /*border: 1px solid;*/
  user-select: none;
  cursor: pointer;
  top: calc(var(--size) * var(--posy));
  left: calc(var(--size) * var(--posx));
  transition-property: top, left, transform;
  transition-duration: 500ms;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell2_group{
  background-color: rgba(0, 0, 0, 0);
  width: calc(var(--size) * 2);
  height: calc(var(--size) * 2);
  padding: 1px;
}

.cell2_inner{
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 10px;
}

.cell2_from{
  animation: cell-hint 400ms infinite alternate-reverse;
}

.cell2_to{
  animation: cell-hint 400ms infinite alternate;
}

@keyframes cell-hint {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.2);
  }
}


.cell-img{
    width: 80%;
    height: 80%;
    position: absolute;
    background-size: contain;
    /*top: 0;
    left: 0;*/
    background-repeat: no-repeat;
}

.game-top-wrapper{
  display: flex;
}

.game-bottom-wrapper{
  display: flex;
}

.game-top-moves{
  padding: 10px;
}

.game-top-counters{
  display: flex;
  padding: 10px;
}

.ani_cell{
  width: 100px;
  height: 100px;
  background-image: url('./imgs/ani-test.png');
  background-size: calc(100% * 3) 100%;
  animation: 500ms ani_cell_keys infinite forwards;
  animation-timing-function: steps(3);
}

@keyframes ani_cell_keys{
  0%{
    background-position-x: 0;
  }

  100%{
    background-position-x: 300%;
  }
}