.wf_grass{
    border-radius: 100%;
    width:calc(var(--base) * 16);
    height:calc(var(--base) * 16);
    background-color: #0f00;
    opacity: 0.5;
    position: absolute;
    pointer-events: none;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
}