.pg_page{
    font-family: Arial, Helvetica, sans-serif;
}

.pg_other_projects{
 
}

.pg_main_projects_list{
    display: flex;
    flex-wrap: wrap;
    --cols: 2;
    --gap: 10px;
    gap: var(--gap);
    background-color: #224;
    padding: 0px 10px;
}

.pg_other_projects_wrapper{
    background-color: #224;
    padding: 10px 10px;
    color: #ccc;
}

.pg_other_projects_title{
    padding: 00px 0px;
    font-size: 36px;
    margin-bottom: 20px;
}

.pg_other_projects_text{
    margin-bottom: 20px;
}

.pg_other_projects_list{
    display: flex;
    flex-wrap: wrap;
    --cols: 3;
    --gap: 10px; 
    gap: var(--gap);
}

@media (max-width: 1000px) {
    .pg_main_projects_list{
        --cols:1;
    }
    .pg_other_projects_list{
        --cols:2;
    }
}

@media (max-width: 600px) {
    .pg_other_projects_list{
        --cols:1;
    }
}
