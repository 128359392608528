.wf_animal{
    position: absolute;
    width:calc(var(--base) * 45);
    height:calc(var(--base) * 45);
    transform: translate(-50%, -50%);
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    transition-property: transform, left, top;
    z-index: 2;
    pointer-events: none;
 }
 