.field_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.field_row{
    display: flex;
}

.field_cell{
    background-color: rgb(0, 0, 0);
    border: 1px solid #400;
    --border-color: #0f0;
}

.field_cell_base{
    width: var(--size);
    height: var(--size);
}

.field_cell_top{
    border-top: 2px solid var(--border-color);
}

.gameScreen_wrapper{
    position: relative;
    height: 100%;
    overflow: hidden;
    width: 100%;
    padding: 10px;
    background-color: #222;
}

.field_cell_left{
    border-left: 2px solid var(--border-color);
}

.field_cell_right{
    border-right: 2px solid var(--border-color);
}

.field_cell_bottom{
    border-bottom: 2px solid var(--border-color);
}

.field_cell_rt{
    border-top-right-radius: 5px;
}

.field_cell_rb{
    border-bottom-right-radius: 5px;
}

.field_cell_lt{
    border-top-left-radius: 5px;
}

.field_cell_lb{
    border-bottom-left-radius: 5px;
}

.field_cell_empty{
    background-color: #ddd2;
    border: 1px solid #fff2
}