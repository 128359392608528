.wf_gameScreen{
    position: relative;
    width: 100%;
    height: 100%; 
    background-color: #43723f;
    user-select: none;
}

.wf_centralZone{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width:calc(var(--base) * 500);
    height:calc(var(--base) * 300);
}

.wf_animalsZone{
    background-color: #ba9658;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 2 * calc(var(--base) * 80));
    height: 100%;
}

.wf_factory_buildButtons{
    position: absolute;
    left: -1calc(var(--base) * 0);
    right: auto;
    bottom:calc(var(--base) * 0);    
    transform: translate(-100%, 0);
}

.wf_factory_buildButtons_right{
    position: absolute;
    left: auto;
    right: -1calc(var(--base) * 0);  
    transform: translate(100%, 0);
}

.wf_factory_buildButton{
    width:calc(var(--base) * 50);
    border-radius:calc(var(--base) * 5);
    border: none;
    background-color: #75a7fd;
    margin-top:calc(var(--base) * 5);
    display: block;
}

.wf_factorySlot{
    background-color: #ba9658;
    position: absolute;
    width:calc(var(--base) * 81);
    height:calc(var(--base) * 80);
    --br:calc(var(--base) * 20);
    z-index: -1;
}

.wf_factorySlot_1{
    left: 0%;
    top: 0%;
    transform: translate(0%, 0%);
    border-top-left-radius: var(--br);
    border-bottom-left-radius: var(--br);
    z-index: -1;
}
.wf_factorySlot_2{
    left: 0%;
    top: 50%;
    transform: translate(0%, -50%);
    border-top-left-radius: var(--br);
    border-bottom-left-radius: var(--br);
}
.wf_factorySlot_3{
    left: 0%;
    top: 100%;
    transform: translate(0%, -100%);
    border-top-left-radius: var(--br);
    border-bottom-left-radius: var(--br);
}
.wf_factorySlot_4{
    right: 0%;
    top: 0%;
    transform: translate(0%, 0%);
    border-top-right-radius: var(--br);
    border-bottom-right-radius: var(--br);
}
.wf_factorySlot_5{
    right: 0%;
    top: 50%;
    transform: translate(0%, -50%);
    border-top-right-radius: var(--br);
    border-bottom-right-radius: var(--br);
}
.wf_factorySlot_6{
    right: 0%;
    top: 100%;
    transform: translate(0%, -100%);
    border-top-right-radius: var(--br);
    border-bottom-right-radius: var(--br);
}

.wf_waterSlot{
    left: 50%;
    top: -40%;
    transform: translate(-50%, 40%);
    border-radius: var(--br);
}
.wf_carSlot{
    left: 20%;
    top: 137%;
    transform: translate(-20%, -140%);
    border-bottom-left-radius: var(--br);
    border-bottom-right-radius: var(--br);
}
.wf_planeSlot{
    left: 80%;
    top: 137%;
    transform: translate(-80%, -140%);
    border-bottom-left-radius: var(--br);
    border-bottom-right-radius: var(--br);
}
.wf_storageSlot{
    left: 50%;
    top: 140%;
    transform: translate(-50%, -140%);
    z-index: -1;
}

.wf_flying_container{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
}

.wf_flying_item{
    position: absolute;
    width:calc(var(--base) * 24);
    height:calc(var(--base) * 24);
    animation: flying 1s forwards linear;
    offset-rotate: 0deg;
}

@keyframes flying{
    0% {
        offset-distance: 0%;
    }
    100% {
        offset-distance: 100%;
    }
}

.wf_paused{
     animation-play-state: paused !important;
}

.wf_icon{
    background-size: cover;
    background-repeat: no-repeat;
    width:calc(var(--base) * 24);
    height:calc(var(--base) * 24);
}

.wf_progress_wrapper{
    width: 100%;
    height:calc(var(--base) * 6);
    border-radius:calc(var(--base) * 3);
    border:calc(var(--base) * 1) solid #999;
    background-color: #fff;
}

.wf_progress{
    height: 100%;
    background-color: #43723f;
}