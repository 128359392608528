.wf_cell{
    width: 15px;
    height: 15px;
    border: 1px dotted #fff4;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d76b00;
    background-color: #000;
    font-family: monospace;
    flex-shrink: 0;

    width: 10px;
    height: 10px;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: -7px;
    justify-content: left;
    padding-left: 2px;
}

.canvas{
    border: 1px dotted #f00;
}