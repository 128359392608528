.editor_wrapper{
    position: relative;
    --size: 30px;
    --field-x: 10;
    --field-y: 10;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.editor_field{
    position: relative;
    height: 100%;
}

.tool{

}

.tool_selected{
    background-color: #999;
}

.editor_objects{
    position: absolute;
    pointer-events: none;
    width: calc(var(--size) * var(--field-x));
    height: calc(var(--size) * var(--field-y));
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}