.proCard_wrapper{
    background-color: #224;
    padding: 10px 0px;
    color: #ccc;
    width: calc((100% - (var(--cols) - 1)* var(--gap)) / var(--cols));
    display: flex;
    flex-direction: column;
}

.proCard_title{
    padding: 0px 0px;
    font-size: 31px;
    line-height: 33px;
    display: flex;
    border-bottom: 1px #ccc solid;
}
.proCard_title_border{
    clip-path: polygon(0 10px, 10px 0, calc(100% - 40px) 0, 100% 40px, 0 40px);
    background-color: #ccc;
    border: 1px transparent solid;
    border-bottom: none;
    position: relative;
    top:1px;
}

.proCard_title_back{
    background: linear-gradient(0deg, #224, #191738);
    clip-path: polygon(0 10px, 10px 0, calc(100% - 40px) 0, 100% 40px, 0 40px);
    padding: 0px 10px;
    padding-right: 40px;
    padding-top:5px;
}

.proCard_technologies{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 10px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.proCard_technology_1{
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 18px;
    line-height: 16px;
}
.proCard_technology{
    font-family: arial;
    font-size: 12px;
    padding: 1px 10px;
    border: 1px solid #3eac3c;
    border-radius: 18px;
    color: #5fe55d;
    line-height: 16px;
    background: linear-gradient(45deg, #00600b80, transparent);
    box-shadow: #000 1px 1px 4px;
}

.proCard_text{
    width: 50%;
}

.proCard_links{
    display: flex;
    gap:10px;
    padding: 10px 10px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    box-shadow: 3px 6px 10px -3px #0005;
}

.proCard_center{
    display: flex;
    gap: 30px;    
    padding: 10px 10px;
    align-items: flex-start;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    height: 100%;
}

.proCard_img{
    width: 100%;
    background-color: #4a75cc;
    position: relative;
}

.proCard_img_unwrapped{
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 2;
}

.proCard_full_exit{
    border: none;
    font-size: 0px;
    border-radius: 5px;
    background-color: #fff9;
    background-image: url('./arrows.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}
.proCard_runDemo{
    border-radius: 50%;
    width: 100px;
    border: 15px #0009 solid;
    background: transparent;
    height: 100px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(./triangle.svg);
    background-size: 48px;
    color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
}

.proCard_runDemo_out{
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    border: 1px #fff solid;
}

.proCard_runDemo_in{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px #fff solid;
    display: flex;
    flex-shrink: 0;
}


.proCard_runDemo span{
    max-width: 13px;
    margin-left: -10px;
    line-height: 6px;
    font-size: 8px;
}

.proCard_runDemo:hover{
    border: 15px #0009 solid;
    background-color: #fff6;
}

.proCard_img::before{
    content: "";
    display: block;
    padding-bottom: 70%;
}

.proCard_img_content{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.proCard_img_ext{
    object-fit: contain;
    object-position: center;
    position: relative;
    display: block;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    height: 100%;
}

.demo_iframe{
    display: block;
    border: none;
    height: 100%;
    width: 100%;
}

.proCard_link{
    background-color: #66c4a6;
    padding: 5px 10px;
    transform: skewX(-15deg);
    margin-left: 7px;
    box-shadow: 1px 1px 0px #2b3f40, 3px 2px 0px #3b8a8d;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    color: #fff;
    text-shadow: 1px 1px 0px #3b3b3b;
    user-select: none;
    cursor: pointer;
}

.proCard_link:hover{
    background-color: #7fe7c6;
}

.proCard .aniSlider{
    height: 100%;
}

.proCard .aniSlider_wrapper{
    height: 100%;
    padding: 0px;
}

.proCard .aniSlider_slide{
    overflow: hidden;
    padding: 0px 5px;
}

.proCard .aniSlider_slide_content{
    height: 100%;
    left: unset;
    top: unset;
}

.proCard .aniSlider_slides_rescaler{
    height: 100%;

}

.proCard_img_slide {
    object-fit: contain;
    object-position: center;
    position: relative;
    display: block;
    height: 100%;
}

.thick_font_wrapper{  
    position: relative;
    font-family: arial;
    font-weight: bolder;
}
.thick_font_top{
    color: #000;
    position: relative;
    font-weight: bolder;
    background-clip: text;
    padding: 0px 3px;
}

.thick_font_border{
    padding: 0px 3px;
    color: #0000;
    position: absolute;
    font-weight: bolder;
    background-clip: text;
    background-image: linear-gradient(180deg, #00b677, #006ba5);
    -webkit-text-stroke: 6px;
}

@media (max-width: 600px) {
    .proCard_center{
        flex-direction: column;
    }
    .proCard_text{
        width: 100%;
    }
}