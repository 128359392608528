.letterListOpenButton_wrapper{
    position: absolute;
    bottom: 10px;
    left: 10px;
    border: 2px solid #b8a785;
    border-top: 2px solid #fff0d3;
    border-left: 2px solid #fff0d3;
}

.letterListOpenButton_button{
    display: block;
    border: none;
    background-color: rgb(252, 185, 91);
    width: 50px;
    height: 50px;
    color: #fff;
    user-select: none;
    cursor: pointer;
}

.letterListOpenButton_count{
    font-size: 16px;
}