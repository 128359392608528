.wf_winPopup{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:calc(var(--base) * 16);
    background-color: #9ec57c;
    border-radius:calc(var(--base) * 5);
    border:calc(var(--base) * 3) solid #c0730e;
}

.wf_winPopup_header{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size:calc(var(--base) * 30);
    font-weight: bold;
    letter-spacing:calc(var(--base) * 3);
    -webkit-text-stroke:calc(var(--base) * 1);
    -webkit-text-stroke-color: #c2811a;
    color: transparent;
    background-clip: text;
    background-image: linear-gradient(45deg, #ffa03f 40%, #fff4e3 50%, #ffa03f 60%);
}