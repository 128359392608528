.base_popup_shadow{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0006;
    display: flex;
    z-index: 10;
    justify-content: center;
    align-items: center;
}

.base_popup_window{
    background-color: #fff;
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
}

.base_screen{
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column; 
    align-items: center;
}
