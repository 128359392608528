.paralax{
    width: 100%;
    height: 100%;
}

.paralax_picture{
    background-image: url(../assets/code.png);
    width: 100%;
    height: 100%;
    /*background-size: 18%;*/
    background-repeat: repeat;
}