.wf_productSlot{
    width:calc(var(--base) * 55);
    height:calc(var(--base) * 50);
    position: relative;
    border:calc(var(--base) * 1) solid;
    border-radius:calc(var(--base) * 3);
    padding:calc(var(--base) * 3);
    margin:calc(var(--base) * 3);
}

.wf_productSlot_image{
   width: 100%;
   height: 100%;
   background-size: contain;
   background-repeat: no-repeat;
}

.wf_productSlot_counts{
    position: absolute;
    top: 0;
    right: 0;    
    display: flex;
    flex-direction: column-reverse;
}

.wf_productSlot_countItem{
    width:calc(var(--base) * 3);
    height:calc(var(--base) * 3);
    margin:calc(var(--base) * 1);
    background-color: #999;
}

.wf_productSlot_countItem_filled{
    background-color: #0f0;
}
