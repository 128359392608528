.wf_missions_container{
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.wf_missions{
    display: flex;
}

.wf_missionItem{
    position: relative;
    background-color: #ffd874;
    margin:calc(var(--base) * 2);
    padding:calc(var(--base) * 0);
    font-size:calc(var(--base) * 9);
    width:calc(var(--base) * 65);
    height:calc(var(--base) * 65);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius:calc(var(--base) * 5);
    border:calc(var(--base) * 3) solid #c0730e;
    color: #955e16;
    font-weight: bold;
}

.wf_missionItem_value{
    font-size:calc(var(--base) * 12);
}

.wf_missionItem_complete{
    background-color: #00b463;
    margin:calc(var(--base) * 2);
    padding:calc(var(--base) * 5);
}

.wf_missionItem_img{
    width:calc(var(--base) * 32);
    height:calc(var(--base) * 32);
    background-repeat: no-repeat;
    background-size: contain;
}

.wf_missionItem_ok{
    width:calc(var(--base) * 16);
    height:calc(var(--base) * 16);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom:calc(var(--base) * 5);
    right:calc(var(--base) * 5);
}