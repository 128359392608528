.wf_movingPanel{
    position: absolute;
    right: 0;
    top: 0;
    width:calc(var(--base) * 200);
    height:calc(var(--base) * 80);
    background-color: #ffd874;
    margin:calc(var(--base) * 2);
    padding:calc(var(--base) * 0);
    border-radius:calc(var(--base) * 5);
    border:calc(var(--base) * 3) solid #c0730e;
    color: #955e16;
    font-weight: bold;
}

.wf_movingCar{
    position: absolute;
    width:calc(var(--base) * 20);
    height:calc(var(--base) * 20);
    background-color: #20b85a;
    bottom:calc(var(--base) * 5);
    left:calc(var(--base) * 0);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:calc(var(--base) * 11);
}

.wf_movingCar_active{
    animation: moveCarAni 1s forwards linear;
}

.wf_movingPlane{
    bottom:calc(var(--base) * 27);
}

@keyframes moveCarAni{
    0%{
        left: 0%;
        transform: translate(0, 0);
    }
    40%{
        left: 100%;
        transform: translate(-100%, 0);
    }
    50%{
        left: 100%;
        transform: translate(-100%, 0) rotateY(180deg);
    }
    90%{
        left: 0%;
        transform: translate(0, 0) rotateY(180deg);
    }
    100%{
        left: 0%;
        transform: translate(0, 0);
    }
}
