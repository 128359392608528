.letterList_popup{
    position: absolute;
    border-radius: 5px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.letterList_popup_back{    
    padding: 10px 20px;
    padding-top: 30px;
    border: 2px solid #b8a785;
    border-top: 2px solid #fff0d3;
    border-left: 2px solid #fff0d3;
    box-shadow: #0004 2px 5px 8px;
    background-color: #ffe3a1;
    clip-path: polygon(0 0, calc(100% - 50px) 0%, 100% 50px, 100% 100%, 0 100%);
}

.letterList_popup_back::before{
    content: '';
    position: absolute;
    right: -2px;
    top: -2px;
    background: #fff5d1;
    width: 54px;
    height: 54px;
    box-shadow: #0009 1px 1px 8px;
}

.letterList_closeButton{
    position: absolute;
    right: 8px;
    top: 8px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: none;
    user-select: none;
    background: rgb(253 110 34);
    color: #fff;
    font-size: 16px;
    box-shadow: #0009 1px 1px 3px;
    cursor: pointer;
    z-index: 3;
}

.letterList_wrapper{
    display: flex;
    flex-wrap: wrap;
    width: calc((30px + 2px) * 7);
}

.letterList_cell{
    background-color: rgb(252, 185, 91);
}

.letterList_cell_empty{
    background-color: #999;
}

.letterList_item{

}

.letterList_count{
    font-size: 11px;
    color: #a96300;
    text-align: center;
    margin-bottom: 5px;
}

.letterList_header{
    border-bottom: 1px solid #c48400;
    color: #c48400;
    font-weight: bold;
    margin-bottom: 10px;
}