.wf_storage_back{
    background-size: contain;
    background-repeat: no-repeat;
    width: 170%;
    height: 170%;
    position: absolute;
    top: calc(50% - calc(var(--base) * 10));
    left: 50%;
    transform: translate(-50%, -50%);
}
.wf_storage{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    height: 100%;
    width: fit-content;
    align-items: flex-start;
    justify-content: flex-start;
    padding:calc(var(--base) * 18);
    padding-top:calc(var(--base) * 48);
    padding-bottom:calc(var(--base) * 10);
}

.wf_storage_item{
    width:calc(var(--base) * 10);
    height:calc(var(--base) * 10);
    background-color: #0009;
    background-size: contain;
    background-repeat: no-repeat;
    margin:calc(var(--base) * 2);
}

.wf_storage_upgradeButton_wrapper{
    width: 100%;
}

.wf_storage_upgradeButton{
    display: flex;
    margin: auto;
}