.level_list{
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    width: 400px;
    height: 525px;
    flex-shrink: 0;
}

.level_button_wrapper{
    margin: 5px;
    width: calc(100% / 3 - 10px);
    height: calc(100% / 4 - 10px);
}

.level_button{
    margin: 5px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: #99a;
}

.level_button:hover{
    background-color: #bbc;
}

.menu_header{
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 5px;
    font-size: 20px;
    font-family: sans-serif;
}

.level_button_complete{
   
}

.level_button_locked{
    background-image: url(../../imgs/lock_icon.png);
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 2px 5px;
}