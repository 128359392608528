.wf_carPopup{
    position: relative;
    padding:calc(var(--base) * 10);
    background-color: #fff;
    top:calc(var(--base) * 0);
    left:calc(var(--base) * 0);
    display: flex;
    width:calc(var(--base) * 500);
    height:calc(var(--base) * 300);
}

.wf_carPopup_close{
    position: absolute;
    top: 0;
    right: 0;
}

.wf_carPopup_storageBlock{
    width: 100%;
    padding-right:calc(var(--base) * 5);
    padding-top:calc(var(--base) * 30);
    background-color: #ffe0b0;
    overflow-y: auto;
}

.wf_carPopup_storageList{

}

.wf_carPopup_storageItem{
    display: flex;
    background-color: #ccc;
    padding:calc(var(--base) * 2);
    margin:calc(var(--base) * 0)calc(var(--base) * 0);
    align-items: center;
    justify-content: space-between;
}

.wf_carPopup_storageItem:nth-child(2n){
    background-color: #aaa;
}
                    
.wf_carPopup_carBlock{
    width: 100%;
    padding-left:calc(var(--base) * 5);
    padding-top:calc(var(--base) * 30);
    background-color: #95d898;
}

.wf_carPopup_carList{

}

.wf_carPopup_carItem{
    display: flex;
}

.wf_carPopup_slots{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #936d3b;
    padding:calc(var(--base) * 20)calc(var(--base) * 0);
    border-top-right-radius:calc(var(--base) * 30);
    border-top-left-radius:calc(var(--base) * 30);
}

.wf_carPopup_slot{
    width:calc(var(--base) * 30);
    height:calc(var(--base) * 30);
    border:calc(var(--base) * 1) solid;
}

.wf_carPopup_storageItem_img{
    width:calc(var(--base) * 32);
    height:calc(var(--base) * 32);
    background-repeat: no-repeat;
    background-size: contain;
}

.wf_carPopup_totalSum{
    display: flex;
    align-items: center;
    background-color: #5e99ff;
    width: fit-content;
    margin:calc(var(--base) * 10) auto;
    padding:calc(var(--base) * 2)calc(var(--base) * 5);
    border-radius:calc(var(--base) * 5);
    color: #fff;
}

.wf_carPopup_sellButton{
    display: block;
    margin: auto;
}

.wf_carPopup_totalSum div{
    margin-right:calc(var(--base) * 10);
}