.spinDigit_container{
    display: flex;
    flex-direction: column;
    width: 30px;
    height: 30px;
    overflow: hidden;
    background-color: grey;
    color: white;
    font-size: 20px;
    position: relative;
}

.spinDigit_digit{
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    transition-duration: 2s;
    transition-property: top;
}

.spinDigit_digit_animate{
    animation: digit_spin 2s infinite backwards linear;
}

.spinDigit_digit_in{
    transition-timing-function: ease-in;
}

.spinDigit_digit_out{
    transition-timing-function: ease-out;
}

@keyframes digit_spin{
    0%{
        top:0%;
    }
    100%{
        top:-1000%;
    }
}

.spinNumber_container{
    display: flex;
}