.wordResult_popup{
    position: absolute;
    background-color: #ffe3a1;
    padding: 10px 20px;
    border-radius: 5px;
    border: 2px solid #b8a785;
    border-top: 2px solid #fff0d3;
    border-left: 2px solid #fff0d3;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: #0004 2px 5px 8px;
}

.wordResult_score{
    font-size: 40px;
    color: #fff;
    margin: auto;
    text-align: center;
    font-family: arial;
    -webkit-text-stroke: #b8a785 1px;
}

.cell_show{
    animation: showLetter 700ms forwards linear;
    animation-delay: var(--delay);
    transform: scale(0);
}

.cell_shown{
    transform: scale(1);
}


.cell_boost_2w{
    transform: scale(1);
    animation-delay: var(--delay);
    animation: boost_2w 700ms forwards linear;
}

.cell_boost_2l{
    transform: scale(1);
    animation-delay: var(--delay);
    animation: boost_2l 700ms forwards linear;
}

.cell_boost_3w{
    transform: scale(1);
    animation-delay: var(--delay);
    animation: boost_3w 700ms forwards linear;
}

.cell_boost_3l{
    transform: scale(1);
    animation-delay: var(--delay);
    animation: boost_3l 700ms forwards linear;
}

.cell_boosted_2w{
    background-color: #090;
}

.cell_boosted_2l{
    background-color: #99f;
}

.cell_boosted_3w{
    background-color: #f66;
}

.cell_boosted_3l{
    background-color: rgb(255, 238, 51);
    border-color: rgb(255, 149, 0);
    -webkit-text-stroke: #b8a785 1px;
}

@keyframes showLetter{
    0% {
        transform: scale(0);
    }

    20% {
        transform: scale(0.3);
    }

    50% {
        transform: scale(1.3);
    }

    70% {
        transform: scale(1.2);
    }

    100%{
        transform: scale(1);
    }
}

@keyframes boost_2w{
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
        background-color: #090;
    }

    100%{
        transform: scale(1);
        background-color: #090;
    }
}

@keyframes boost_2l{
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.4);
        background-color: #99f;
    }

    100%{
        transform: scale(1);
        background-color: #99f;
    }
}

@keyframes boost_3w{
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
        background-color: #f66;
    }

    100%{
        transform: scale(1);
        background-color: #f66;
    }
}

@keyframes boost_3l{
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.6);
        background-color: rgb(255, 238, 51);
        border-color: rgb(255, 149, 0);
        -webkit-text-stroke: #b8a785 1px;
    }

    100%{
        transform: scale(1);
        background-color: rgb(255, 238, 51);
        border-color: rgb(255, 149, 0);
        -webkit-text-stroke: #b8a785 1px;
    }
}