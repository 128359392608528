.wf_planePopup{
    position: relative;
}

.wf_planePopup_planePanel{
    width: 100%;
}

.wf_planePopup_storePanel{
    width: 100%;
}