.message_wrapper{
    position: absolute;
    left: 50%;
    top: 50%;
    color: #fff;
    font-size:calc(var(--base) * 20);
    text-shadow: #000 calc(var(--base) * 0)calc(var(--base) * 0)calc(var(--base) * 10);
    pointer-events: none;
    animation: messageAni 2s forwards linear;
    transform: translate(-50%, -50%);
}

@keyframes messageAni{
    0% {
        top: 100%;
        transform: translate(-50%, 0%);
    }

    20% {
        top: 60%;
        transform: translate(-50%, -50%);
    }

    80% {
        top: 40%;
        transform: translate(-50%, -50%);
    }

    100% {
        top: 0%;
        transform: translate(-50%, -100%);
    }
}