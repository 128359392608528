.wf_animalsPanel{
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    height:calc(var(--base) * 50);

    background-color: #ffd874;
    margin:calc(var(--base) * 2);
    padding:calc(var(--base) * 0);
    border-radius:calc(var(--base) * 5);
    border:calc(var(--base) * 3) solid #c0730e;
    color: #955e16;
    font-weight: bold;
}

.wf_addAnimal_item{
    font-size:calc(var(--base) * 11);
    margin:calc(var(--base) * 5);
    padding:calc(var(--base) * 5);
    background-color: #955e16;
    color: #ffd874;
    border-radius:calc(var(--base) * 5);
    border:calc(var(--base) * 1) solid #c0730e;
}

.wf_addAnimal_item_disabled{
    background-color: #ffd874;
    color: #955e16;
}